import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocations, usePersonData } from "../../data/provider";
import Sheet from 'react-modal-sheet';
import Slider from '@mui/material/Slider';
import { FinalDestinationFilters, MilitancyFilters, PeopleQueryFilter } from "../../data/types";
import './index.css'
import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import { useScreenWidth } from "../../providers/ScreenWidth";

const ResultItem = ({id}: {id: string}) => {
  const { people, selected, unselect, select } = usePersonData();

  const isChecked = useMemo(() => {
    return selected.includes(id)
  }, [selected, id])

  const handleCheckbox = useCallback(() => { 
    isChecked ? unselect(id) : select(id) 
  }, [id, isChecked, select, unselect])

  return (
    <FormControlLabel 
      id={id}
      label={people[id].name}
      control={
        <Checkbox 
          checked={selected.includes(id)}
          onChange={handleCheckbox}/>
      }/>
  );
}

enum ModalState {
  Results,
  Filters,
}



const Legend = () => {
  // return (<h4>Legend</h4>)
  return <br></br>
}

const ResultsHeader = ({modalState, onToggle}: {modalState: ModalState, onToggle: () => void}) => {
  const { people, selectAll, unselectAll, clearFilters } = usePersonData();
  const {isDivided} = useScreenWidth();
  const count = useMemo(() => Object.keys(people).length, [people])
  return (
    <div style={{display: 'flex', alignItems: 'center', marginBottom: '1em'}}>
      <div style={{flex: 1}}>{`${count} Results`}</div>
      {/* <button onClick={selectAll}>
        Select all
      </button>
      <button onClick={unselectAll}>
        Unselect all
      </button>
      <button onClick={clearFilters}>
        Clear filters
      </button>
      {!isDivided && (
        <button onClick={onToggle}>
          Show {modalState === ModalState.Filters ? 'Results' : 'Filters'}
        </button>
      )} */}
    </div>
  );
}

const ResultsList = ({onToggle}: { onToggle: () => void}) => {
  const { people, selectAll, unselectAll } = usePersonData();
  const {isDivided} = useScreenWidth();
  return (
    <div style={{overflowY: 'scroll'}}>
      <div style={{position: 'sticky', top: 0}}>
      <button onClick={selectAll}>Select All</button>
      <button onClick={unselectAll}>Unselect All</button>
      {!isDivided && (
        <button onClick={onToggle}>Filters</button>
      )}
      </div>
      <FormGroup>
        {people && (Object.keys(people).map(key => <ResultItem key={key} id={key} />))}
      </FormGroup>
    </div>
  );
}

const DEFAULT_AGE_RANGE = [10, 90]
const ResultsFilters = ({onToggle, disableDrag}: {onToggle: () => void, disableDrag: (val: boolean) => void}) => {
  const { selectedFilters, clearFilters, setFilters } = usePersonData();
  const [componentAgeRange, setComponentAgeRange] = useState<number[]>(selectedFilters.age ?? DEFAULT_AGE_RANGE);
  const [filterAgeRange, setFilterAgeRange] = useState<number[]>(selectedFilters.age ?? DEFAULT_AGE_RANGE);
  const [finalDestinationFilter, setFinalDestinationFilter] = useState<FinalDestinationFilters[]>(selectedFilters.finalDestination ?? [])
  const [militancyFilter, setMilitancyFilter] = useState<MilitancyFilters[]>(selectedFilters.militancy ?? [])
  const [nameFilter, setNameFilter] = useState<string>(selectedFilters.name ?? '')
  const {isDivided} = useScreenWidth();
  const locations = useLocations();
  const [locationsFilter, setLocationsFilter] = useState<string[]>(selectedFilters.locations ?? [])

  const handleChange = (event: Event, newValue: number | number[]) => {
    disableDrag(true)
    setComponentAgeRange(newValue as number[]);
  };

  const handleChangeCommit = (_: any, value: number | number[]) => {
    disableDrag(false)
    setFilterAgeRange(value as number[])
  }

  const filterQuery = useMemo(() => {
    const query: PeopleQueryFilter = {}
    query.age = filterAgeRange
    query.finalDestination = finalDestinationFilter
    query.militancy = militancyFilter
    query.name = nameFilter
    query.locations = locationsFilter
    return query
  }, [filterAgeRange, finalDestinationFilter, militancyFilter, nameFilter, locationsFilter])

  const clearAllFilters = useCallback(() => {
    setComponentAgeRange(DEFAULT_AGE_RANGE)
    setFilterAgeRange(DEFAULT_AGE_RANGE)
    setFinalDestinationFilter([])
    setMilitancyFilter([])
    setLocationsFilter([])
    setNameFilter('')
    clearFilters()
  }, [clearFilters])

  useEffect(() => {
    setFilters(filterQuery)
  }, [filterQuery, setFilters])

  return (
    <div>
      <button onClick={clearAllFilters}>Clear filters</button>
      {!isDivided && (
        <button onClick={onToggle}>Show Results</button>
      )}
      <div>Name</div>
      <TextField
        label="Name"
        value={nameFilter}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setNameFilter(event.target.value);
        }}
      />
      <div>Age</div>
      <Slider
        getAriaLabel={() => 'Age range'}
        valueLabelDisplay="auto"
        value={componentAgeRange}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommit}
        min={10}
        max={90}
      />
      <div>Militancy</div>
      <FormGroup>
        {
          Object.values(MilitancyFilters).map((militancy) => (
            <FormControlLabel
              key={militancy}
              control={
                <Checkbox 
                  checked={militancyFilter.includes(militancy)}
                  onChange={() =>
                    militancyFilter.includes(militancy) ?
                      setMilitancyFilter(m => m.filter(i => i !== militancy))
                      :
                      setMilitancyFilter(m => [...m, militancy])
                  }/>
              } 
              label={militancy} />
          ))
        }
      </FormGroup>
      <div>Final Destination</div>
      <FormGroup>
        {
          Object.values(FinalDestinationFilters).map((finalDestination) => (
            <FormControlLabel 
              key={finalDestination}
              control={
                <Checkbox 
                  checked={finalDestinationFilter.includes(finalDestination)}
                  onChange={() =>
                    finalDestinationFilter.includes(finalDestination) ?
                      setFinalDestinationFilter(fd => fd.filter(i => i !== finalDestination))
                      :
                      setFinalDestinationFilter(fd => [...fd, finalDestination])
                  }/>
              } 
              label={finalDestination} />
          ))
        }
      </FormGroup>
      <div>Locations</div>
      <FormGroup>
        {
         locations.map((location) => (
            <FormControlLabel 
              key={location.sys.id}
              control={
                <Checkbox 
                  checked={locationsFilter.includes(location.sys.id)}
                  onChange={() =>
                    locationsFilter.includes(location.sys.id) ?
                      setLocationsFilter(lo => lo.filter(i => i !== location.sys.id))
                      :
                      setLocationsFilter(lo => [...lo, location.sys.id])
                  }/>
              } 
              label={location.name} />
          ))
        }
      </FormGroup>
    </div>
  )
}

const ResultsContent = ({disableDrag}: {disableDrag: (val: boolean) => void}) => {
  const [modalState, setModalState] = useState(ModalState.Results);
  const {isDivided} = useScreenWidth();

  const toggleModalState = useCallback(() => {
    setModalState(s => s === ModalState.Filters ? ModalState.Results : ModalState.Filters)
  }, [])
  
  return (
    <>
      <ResultsHeader modalState={modalState} onToggle={toggleModalState} />
      {isDivided && (
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}}>
            
          <ResultsList onToggle={() => setModalState(ModalState.Filters)} />
          </div>
          <div style={{flex: 1}}>

          <ResultsFilters onToggle={() => setModalState(ModalState.Results)} disableDrag={disableDrag} />
          </div>
        </div>
      )}
      {!isDivided && (
      <>
        {modalState === ModalState.Results && (
          <ResultsList onToggle={() => setModalState(ModalState.Filters)} />
        )}
        {modalState === ModalState.Filters && (
          <ResultsFilters onToggle={() => setModalState(ModalState.Results)} disableDrag={disableDrag} />
        )}
      </>
      )}
    </>
  );
}

const ResultModal = () => {
  const [isOpen, setOpen] = useState(true);
  const [disableDrag, setDisableDrag] = useState(false);

  useEffect(() => {
    if (isOpen) return;
    setTimeout(() => setOpen(true), 200);
  }, [isOpen]);
  
  return (
    <Sheet 
      isOpen={isOpen} 
      onClose={() => {
        setOpen(false)
      }} 
      snapPoints={[1, 0.618, 0.15]}
      initialSnap={2}
      disableDrag={disableDrag}
      // style={{mixBlendMode: 'difference'}}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <Sheet.Scroller>

          <div className="result-content">
            <h3 style={{margin: 0}}>Trayectorias de desaparición forzada en Chile</h3>
            <Legend />
            <ResultsContent disableDrag={setDisableDrag} />
          
          </div>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
}

export default ResultModal;
