import { PeopleQueryData, ContentfulPeopleQueryFilter, SidebarQueryData, LocationQueryData } from "./types"

const peopleQuery = (filter: ContentfulPeopleQueryFilter) => `
  query($preview: Boolean) {
    people: personCollection(
      preview: $preview,
      order: name_ASC,
      where: ${JSON.stringify(filter).replace(/"([^"]+)":/g, '$1:')}
    ) {
      items {
        sys {
          id
        },
        name,
        age,
        date,
        url,
        militancy,
        locations: locationsCollection {
          items {
            sys {
              id
            }
            name,
            type,
            coordinates {
              lat,
              lon
            }
          }
        }
      }
    }
  }
`

const sidebarQuery = `
  query($preview: Boolean) {
    sidebar: sidebarCollection(preview: $preview) {
      items {
        logo {
          url
        },
        entries: entriesCollection {
          items {
            header,
            body {
              json
            },
            sys {
              id
            }
          }
        }
      }
    }
  }
`

const locationQuery = `
  query($preview: Boolean) {
    locations: contentTypeLocationCollection(
      preview: $preview,
      order: name_ASC,
      limit: 500
    ) {
      items {
        name,
        sys {
          id
        }
      }
    }
  }
`

export function queryPersonData(filter: ContentfulPeopleQueryFilter = {}): () => Promise<PeopleQueryData> {
  return function() {
    const SPACE = process.env.REACT_APP_CONTENTFUL_SPACE_ID
    const ENVIRONMENT = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT
    const TOKEN = process.env.REACT_APP_CONTENTFUL_API_KEY
    const PREVIEW = process.env.REACT_APP_CONTENTFUL_SHOW_PREVIEW
  
    return fetch(`https://graphql.contentful.com/content/v1/spaces/${SPACE}/environments/${ENVIRONMENT}`, {
      method: 'POST',
      // Include Authorization and Content-Type HTTP headers
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        'Content-Type': 'application/json',
      },
      // Send the GraphQL query in the body of the request
      body: JSON.stringify({ query: peopleQuery(filter), variables: { preview: Boolean(PREVIEW) } }),
    }).then(response => response.json())
  }
}

export function querySidebarData(): Promise<SidebarQueryData> {
  const SPACE = process.env.REACT_APP_CONTENTFUL_SPACE_ID
  const ENVIRONMENT = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT
  const TOKEN = process.env.REACT_APP_CONTENTFUL_API_KEY
  const PREVIEW = process.env.REACT_APP_CONTENTFUL_SHOW_PREVIEW

  return fetch(`https://graphql.contentful.com/content/v1/spaces/${SPACE}/environments/${ENVIRONMENT}`, {
    method: 'POST',
    // Include Authorization and Content-Type HTTP headers
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      'Content-Type': 'application/json',
    },
    // Send the GraphQL query in the body of the request
    body: JSON.stringify({ query: sidebarQuery, variables: { preview: Boolean(PREVIEW) } }),
  }).then(response => response.json())
}

export function queryLocationData(): Promise<LocationQueryData> {
  const SPACE = process.env.REACT_APP_CONTENTFUL_SPACE_ID
  const ENVIRONMENT = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT
  const TOKEN = process.env.REACT_APP_CONTENTFUL_API_KEY
  const PREVIEW = process.env.REACT_APP_CONTENTFUL_SHOW_PREVIEW

  return fetch(`https://graphql.contentful.com/content/v1/spaces/${SPACE}/environments/${ENVIRONMENT}`, {
    method: 'POST',
    // Include Authorization and Content-Type HTTP headers
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      'Content-Type': 'application/json',
    },
    // Send the GraphQL query in the body of the request
    body: JSON.stringify({ query: locationQuery, variables: { preview: Boolean(PREVIEW) } }),
  }).then(response => response.json())
}