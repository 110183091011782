import './styles.css'
import { createRef, useMemo } from "react";
import { Marker, Polyline, Popup } from "react-leaflet";
import { Polyline as PolylineLeaflet, Marker as MarkerLeaflet } from "leaflet";
import { LocationData, PersonData } from "../../data/types";
import { getMarkerIcon } from "./icons";

interface TrajectoryProps {
  person: PersonData;
}

interface PersonPopupProps  {
  person: PersonData;
}

export interface LocationPopupProps {
  location: LocationData
}

const PersonPopup = ({person}: PersonPopupProps) => {
  return (
    <Popup 
      className={'person-popup'}
      closeButton={false}
    >
      <a href={person.url} target="_blank" rel="noopener noreferrer">
        <h4>{person.name}</h4>
      </a>
      <text>{`Edad: ${person.age}`}</text><br/>
      <text>{`Militancia: ${person.militancy?.join('/') ?? "None"}`}</text>
    </Popup>
  )
}

const LocationPopup = ({location}: LocationPopupProps) => {
  return (
    <Popup className={'location-popup'} closeButton={false}>
      <h4>{location.name}</h4>
    </Popup>
  )
}

export const Trajectory = ({ person }: TrajectoryProps) => {
  const trajectoryColor = useMemo(() => {
    return 'black'
  }, [])

  // const markerCount = useMemo(() => person.locations.items.length, [person.locations.items.length])

  // const lineRefs = [...Array(2)].map(_ => createRef<PolylineLeaflet>())
  // const markerRefs = [...Array(markerCount)].map(_ => createRef<MarkerLeaflet>())
  
  // const [isHighlighted, setIsHighlighted] = useState(false)
  // const highlightTrajectory = useCallback(() => { setIsHighlighted(true) }, [])
  // const removeTrajectoryHighlight = useCallback(() => { setIsHighlighted(false) }, [])

  // Listen for hover event, set isHighlighted to true
  // useEffect(() => {
  //   [...lineRefs, ...markerRefs].forEach((currentRef) => {
  //     if (currentRef.current) {
  //       currentRef.current.on('click', highlightTrajectory)
  //       currentRef.current.on('popupclose', removeTrajectoryHighlight)
  //     }
  //   })

  //   return () => {
  //     [...lineRefs, ...markerRefs].forEach((currentRef) => {
  //     if (currentRef.current) {
  //       currentRef.current.off('click')
  //       currentRef.current.off('popupclose')
  //     }
  //   })}
  // }, [lineRefs, markerRefs, highlightTrajectory, removeTrajectoryHighlight])

  // // When trajectory isHighlighted, set opacity to 1, when not highlighted, set to 0.2
  // useEffect(() => {
  //   if (isHighlighted) {
  //     lineRefs.forEach(currentRef => {
  //       currentRef.current?.setStyle({opacity: 1})
  //     })
  //     markerRefs.forEach(currentRef => {
  //       currentRef.current?.setOpacity(1)
  //     })
  //   } else {
  //     lineRefs.forEach(currentRef => {
  //       currentRef.current?.setStyle({opacity: 0.2})
  //     })
  //     markerRefs.forEach(currentRef => {
  //       currentRef.current?.setOpacity(0.2)
  //     })
  //   }
  // }, [isHighlighted, lineRefs, markerRefs])

  return (
    <>
      <Polyline
        positions={person.locations.items.map(l => [l.coordinates.lat, l.coordinates.lon])}
        color={trajectoryColor}
        weight={3}
      >
        <PersonPopup person={person} />
      </Polyline>
      {/* <Polyline
        dashArray={[20,20]}
        positions={person.locations.items.slice(0,2).map(l => [l.coordinates.lat, l.coordinates.lon])}
        color={trajectoryColor}
        weight={5}
        ref={lineRefs[0]}
      >
        <PersonPopup person={person} />
      </Polyline>
      <Polyline
        positions={person.locations.items.slice(1).map(l => [l.coordinates.lat, l.coordinates.lon])}
        color={trajectoryColor}
        weight={5}
        ref={lineRefs[1]}
      >
        <PersonPopup person={person} />
      </Polyline> */}
    </>
  );
}
